import { MEDIA_COLLECTIONS } from './consts/photographyConsts';
import {
  MA_APP_IDS,
  addApplications,
} from '@wix/members-area-integration-kit';

export const installAppComponents = async (editorSDK: any) => {
  // install collection page
  const collectionPageCompDescr = {
    componentType: editorSDK.document.tpa.TPAComponentType.Page,
    managingAppDefId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    appDefinitionId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    page: {
      pageId: 'Collection',
      title: 'Collection',
    },
  } as any;
  // install thank you page
  const thankYouPageCompDescr = {
    componentType: editorSDK.document.tpa.TPAComponentType.Page,
    appDefinitionId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    managingAppDefId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    page: {
      pageId: 'ThankYouPage',
      title: 'ThankYouPage',
    },
  } as any;
  await editorSDK.document.tpa.add.component(
    MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    collectionPageCompDescr,
  );
  await editorSDK.document.tpa.add.component(
    MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    thankYouPageCompDescr,
  );

  const pages = await editorSDK.pages.data.getAll(
    MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
  );
  const setPage = await pages.find(
    (page: any) => page.title?.toLowerCase() === 'set',
  );
  const collectionPage = await pages.find(
    (page: any) => page.title?.toLowerCase() === 'collection',
  );
  // install gallery component
  editorSDK.document.tpa.add.component(
    MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    {
      componentType: editorSDK.document.tpa.TPAComponentType.Widget,

      widget: {
        widgetId: MEDIA_COLLECTIONS.GALLERY_WIDGET_ID,
        wixPageId: collectionPage?.id,
      },
      appDefinitionId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
      managingAppDefId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    },
  );
  editorSDK.document.tpa.add.component(
    MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    {
      componentType: editorSDK.document.tpa.TPAComponentType.Widget,
      widget: {
        widgetId: MEDIA_COLLECTIONS.GALLERY_WIDGET_ID,
        wixPageId: setPage?.id,
      },
      appDefinitionId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
      managingAppDefId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    },
  );

  // install cart component
  editorSDK.document.tpa.add.component(
    MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    {
      componentType: editorSDK.document.tpa.TPAComponentType.Widget,
      widget: {
        widgetId: MEDIA_COLLECTIONS.CART_WIDGET_ID,
        wixPageId: collectionPage?.id,
      },
      appDefinitionId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
      managingAppDefId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    },
  );
  // install header icon
  editorSDK.document.tpa.add.component(
    MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    {
      componentType: editorSDK.document.tpa.TPAComponentType.Widget,
      widget: {
        widgetId: MEDIA_COLLECTIONS.HEADER_ICON_WIDGET_ID,
        wixPageId: collectionPage?.id,
      },
      appDefinitionId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
      managingAppDefId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
    },
  );


  addApplications([
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SETTINGS,
  ]);
};
