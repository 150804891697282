import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { installAppComponents } from './appSetup';
import {
  withMembersArea,
  MA_APP_IDS,
  addApplications,
} from '@wix/members-area-integration-kit';

export const editorReady = async (
  editorSDK: any,
  appDefinitionId: any,
  platformOptions: any,
  flowAPI: any,
) => {
  const { reportError, fedops } = flowAPI;

  fedops.appLoadStarted();
  try {
    if (platformOptions.firstInstall) {
      fedops.interactionStarted('install');
      // Place for your app initialization here. (Add pages to editor platform, init configs, etc);
      console.log('App was installed ✅');
      fedops.interactionEnded('install');
      installAppComponents(editorSDK);
    }
  } catch (e: any) {
    reportError(e);
  }
  fedops.appLoaded();
};

const platformApp = {
  editorReady,
  handleAction: () => {},
  getAppManifest: () => {
    return {
      pages: {
        pageActions: {
          default: [],
          collectionPage: [],
        },
        pageSettings: {
          default: [],
          collectionPage: [],
        },
        applicationSettings: {
          default: {
            displayName: 'Collections Pages',
            helpId: '',
          },
        },
        applicationActions: {
          default: [],
        },
      },
    };
  },
} as {
  editorReady: EditorReadyFn;
  handleAction: () => {};
  getAppManifest: any;
};


export default withMembersArea(platformApp, {
  disableADI: false,
  installAutomatically: false,
  installAppsAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SETTINGS,
    MA_APP_IDS.ALL_MEMBERS,
  ],
});
